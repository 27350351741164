import Container from "./Container";
import servicesText from "../resources/svgs/Services.svg";
import videoEditingImage from "../resources/svgs/VideoEditing.svg";
import graphicDesigningImage from "../resources/svgs/GraphicDesigning.svg";
import motiongraphics from "../resources/svgs/motiongraphics.svg";
import planning from "../resources/svgs/planning.svg";
import analytics from "../resources/svgs/analytics.svg";
import smm from "../resources/svgs/smm.svg";

import { Fade } from "react-awesome-reveal";
export default function Services() {
  const servicesData = [
    {
      servicesName: "VIDEO EDITING",
      imagePath: videoEditingImage,
    },
    {
      servicesName: "GRAPHIC DESIGNING",
      imagePath: graphicDesigningImage,
    },
    {
      servicesName: "MOTION GRAPHICS",
      imagePath: motiongraphics,
    },
    {
      servicesName: "STRATEGY, SCRIPTING AND CONTENT PLANNING",
      imagePath: planning,
    },
    {
      servicesName: "YOUTUBE ANALYTICS AND GROWTH HACKS",
      imagePath: analytics,
    },
    {
      servicesName: "SOCIAL MEDIA MANAGEMENT",
      imagePath: smm,
    },
  ];
  return (
    <div className="my-20" id="servicesId">
      <div className="mx-auto my-10 w-3/6">
        <Fade direction="down">
          <img src={servicesText} alt="servicesText" className="mx-auto" />
        </Fade>
      </div>
      <Container>
        <div className="flex flex-wrap justify-center gap-4 md:gap-12 my-10 md:my-20">
          {servicesData.map((currEle, index) => {
            return (
              <div
                key={index}
                className="bg-[#25B6AD] py-10 px-5 rounded-xl w-full md:w-1/3 lg:w-1/4"
              >
                <p className="text-sm md:text-xl h-10 md:h-20 font-semibold text-center">
                  {currEle.servicesName}
                </p>
                <img
                  src={currEle.imagePath}
                  alt={currEle.imagePath}
                  className={`w-[300px] h-[200px] mx-auto`}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
