import AboutUs from "../components/AboutUs";
import BackToTop from "../components/BackToTop";
import Clients from "../components/Clients";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";
import HowCanIHelpYou from "../components/HowCanIHelpYou";
import MainVideo from "../components/MainVideo";
import Services from "../components/Services";
import Works from "../components/Works";
export default function Home() {
  return (
    <div>
      <MainVideo />
      <AboutUs />
      <Clients />
      <HowCanIHelpYou />
      {/* <Tools /> */}
      <Works />
      <Services />
      <Contacts />
      <BackToTop />
      <Footer />
    </div>
  );
}
