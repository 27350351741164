/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Fade } from "react-awesome-reveal";

export default function LongForm() {
  const LongFormData = [
    {
      videoLink:
        "https://www.youtube.com/embed/5uYFfzhDQk0?si=8m7bnA9-S_TQToUF",
    },

    {
      videoLink:
        "https://www.youtube.com/embed/_6DeYWIwObM?si=PP6SUQ18V59G52XS?autoplay=1&loop=1",
    },
    {
      videoLink:
        "https://www.youtube.com/embed/3_FB2_BT1Fc?si=Iq0qmQ3j4vuSIop1",
    },
    {
      videoLink:
        "https://www.youtube.com/embed/YHy3rV8GjZo?si=WYK7wLvWHse4ia_Q",
    },
    {
      videoLink:
        "https://www.youtube.com/embed/M2U5CRYaxlI?si=qE_HTDZdnil-Gjyp",
    },
    {
      videoLink:
        "https://www.youtube.com/embed/QZfcVpVStcw?si=4FRPocKxzuxWZb_a",
    },
  ];
  return (
    <>
      <p className="text-center font-bold text-2xl md:text-4xl lg:text-5xl text-white">
        LONG FORM
      </p>
      <div className="mt-20 w-10/12 md:w-full mx-auto grid md:grid-cols-2 gap-7 ">
        {LongFormData.map((el, index) => (
          <Fade direction="left">
            <div className="bg-[#25B6AD] p-3 h-[300px] rounded-lg">
              <iframe
                src={el.videoLink}
                width="100%"
                height="100%"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen="true"
                webkitRequestFullscreen="true"
                mozRequestFullScreen="true"
              ></iframe>
            </div>
          </Fade>
        ))}
      </div>
    </>
  );
}
