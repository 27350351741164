import Container from "./Container";
import clientsText from "../resources/svgs/OurClients.svg";
import payalImage from "../resources/svgs/Payal.svg";
import architVarmaImage from "../resources/svgs/ArchitVarma.svg";
import jimmyImage from "../resources/svgs/jimmy.svg";
import simplyDigital from "../resources/pngs/simplyDigital.png";
import mmaCoach from "../resources/pngs/mmaCoach.png";
import { Fade } from "react-awesome-reveal";

export default function Clients() {
  const clientsData = [
    {
      clientName: "PAYAL GAMING",
      subscribers: "3.29M SUBSCRIBERS",
      imageSrc: payalImage,
    },
    {
      clientName: "JIMMY7",
      subscribers: "658K SUBSCRIBERS",
      imageSrc: jimmyImage,
    },
    {
      clientName: "ARCHIT VARMA",
      subscribers: "701K SUBSCRIBERS",
      imageSrc: architVarmaImage,
    },
    {
      clientName: "SIMPLY DIGITAL",
      subscribers: "570K FOLLOWERS",
      imageSrc: simplyDigital,
    },
    {
      clientName: "MMA COACH",
      subscribers: "50k SUBSCRIBERS",
      imageSrc: mmaCoach,
    },
    
  ];
  return (
    <div>
      <div className="w-2/6 mx-auto mt-20">
        <Fade direction="down">
          <img src={clientsText} alt="clientsText" className="mx-auto" />
        </Fade>
      </div>
      <Container>
        <div className="w-10/12 mx-auto flex-col flex md:flex-row gap-10 mt-[5rem] mb-[5rem]">
          {clientsData.map((currEle, index) => {
            return (
              <div className="flex flex-col gap-y-5" key={index}>
                <Fade delay={3}>
                  <img
                    //   className="w-[180px] h-[180px]"
                    src={currEle.imageSrc}
                    alt="payalImage"
                    className="w-[80%] sm:w-[50%] lg:w-auto mx-auto"
                  />
                </Fade>
                <div className="flex flex-col gap-1">
                  <p className="text-base md:text-base lg:text-lg font-semibold text-center">
                    {currEle.clientName}
                  </p>
                  <p className="main-color text-lg md:text-sm font-bold text-center">
                    {currEle.subscribers}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
