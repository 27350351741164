/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade } from "react-awesome-reveal";

export default function ShortForm() {
  const CustomPrevArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className=" custom-arrow custom-prev-arrow absolute left-[-50px] md:left--10 top-1/2 transform -translate-y-1/2  rounded-full p-2 focus:outline-none"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#25B6AD"
        class="w-12 h-12 md:w-20 md:h-20"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 19.5 8.25 12l7.5-7.5"
        />
      </svg>
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className="custom-arrow custom-prev-arrow absolute right-[-50px] md:right--10 top-1/2 transform -translate-y-1/2 rounded-full p-2  focus:outline-none"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#25B6AD"
        class="w-12 h-12 md:w-20 md:h-20"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m8.25 4.5 7.5 7.5-7.5 7.5"
        />
      </svg>
    </button>
  );
  const shortFormVideoData = [
    {
      VideoLink: "https://www.youtube.com/embed/JvJdXS2qF-E",
    },
    {
      VideoLink: "https://www.youtube.com/embed/OXjZ6TmFRGI",
    },
    {
      VideoLink: "https://youtube.com/embed/GzY6bMBDVCQ?feature=share",
    },
    {
      VideoLink: " https://youtube.com/embed/UTCFi0iBZew?feature=share",
    },
    {
      VideoLink:
        "https://www.youtube.com/embed/N0ZDdyN9Ovo?si=hWR2HdyWReL6xxso",
    },
    {
      VideoLink: "https://www.youtube.com/embed/0kSWsqnUnKk",
    },
    {
      VideoLink: "https://youtube.com/embed/jbEz_KAIjGs",
    },
    {
      VideoLink: "https://youtube.com/embed/3_pxdDTFC5M",
    },
    {
      VideoLink:
        "https://www.youtube.com/embed/RFr-yDgaMlE?si=x7GZVhvNI0fBaMh-",
    },
    {
      VideoLink: "https://youtube.com/embed/Oa_CxgzO0bQ",
    },
    {
      VideoLink:
        "https://www.youtube.com/embed/1PbYDlDz_0A?si=KWlUo8XCv3prlo3L",
    },
    {
      VideoLink: "https://youtube.com/embed/aR_NdJaR8e4",
    },
    {
      VideoLink: "https://youtube.com/embed/hdD75aelaVo",
    },
  ];
  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fade direction="down">
      <p className="text-center font-bold text-2xl md:text-4xl lg:text-5xl text-white">
        SHORT FORM
      </p>
      <div className="p-6 rounded-lg mt-20  mx-auto">
        <Slider {...settings} className="px-2 sm:px-10">
          {shortFormVideoData.map((el, index) => (
            <div
              key={index}
              className=" md:ml-8"
            >
              <iframe
                width="315"
                height="600"
                src={el.VideoLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media;
gyroscope; picture-in-picture;
web-share"
                allowfullscreen
                className="rounded-xl md:shadow-shortVideoCustom"
              ></iframe>
            </div>
          ))}
        </Slider>
      </div>
    </Fade>
  );
}
