import Container from "./Container";
import VidioticsLogo from "../resources/svgs/VidioticsLogo.svg";
import { Link } from "react-router-dom";
export default function Navbar() {
  const navOptions = [
    {
      name: "PORTFOLIO",
      sectionId: "portfolioId",
    },
    {
      name: "SERVICES",
      sectionId: "servicesId",
    },
    {
      name: "CONTACT",
      sectionId: "contactId",
    },
  ];

  const scrollIntoView = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Container>
        <div className="w-full mx-auto flex-row  md:gap-10 flex md:flex-row justify-between my-10">
          <div className="">
            <img
              src={VidioticsLogo}
              alt="logo"
              className="w-6/12 md:w-6/12 md:mx-0"
            />
          </div>
          <div className=" flex flex-col md:flex-row gap-10">
            {navOptions.map((currEle, index) => {
              return (
                <p
                  onClick={() => scrollIntoView(`${currEle.sectionId}`)}
                  key={index}
                  className="hidden md:block font-bold text-2xl cursor-pointer my-auto"
                >
                  {currEle.name}
                </p>
              );
            })}
          </div>
          <div className="w-full md:w-1/2 my-auto">
            <Link to={"https://calendly.com/amayverma/30min?month=2023-09"} target="_blank">
              <button className="w-full md:mx-auto bg-[#25b6ad] py-1 px-10 md:px-1 md:py-3 font-semibold rounded-lg text-xs md:text-lg">
                Book A Free Consultation
              </button>
            </Link>
          </div>
        </div>
        <div className="w-10/12 mx-auto">
          <hr></hr>
        </div>
      </Container>
    </div>
  );
}
