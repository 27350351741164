import Container from "./Container";
import dotImage from "../resources/svgs/listDot.svg";
import howTextImage from "../resources/svgs/HelpYou.svg";
import { Fade } from "react-awesome-reveal";

export default function HowCanIHelpYou() {
  const servicesData = [
    "Short Form video editing",
    "long Form video editing",
    "Ads for generating leads",
    "Cinematic Storytelling",
    "AI integration",
  ];
  return (
    <div className="my-10">
      <div className="w-3/6 mx-auto my-10">
        <Fade direction="down">
          <img src={howTextImage} alt="howText" className="mx-auto" />
        </Fade>
      </div>
      <Container>
        <div className="w-full md:w-8/12 mx-auto">
          <Fade direction="left">
            <p className="main-color text-xl md:text-2xl font-semibold mt-20 text-left">
              NO MATTER WHERE YOU ARE IN YOUR CREATIVE JOURNEY, WE'RE HERE TO BE
              YOUR VIDEO EDITING ALLY, TURNING YOUR IDEAS INTO CAPTIVATING
              REALITY.
            </p>
          </Fade>
        </div>
        <div className="w-full xl:w-4/6 mx-auto">
          <Fade direction="right">
            <p className="text-xl md:text-2xl font-bold text-left md:text-center mt-10">
              HERE'S A GLIMPSE OF WHAT WE CAN DO FOR YOU:
            </p>
          </Fade>
          <div className="mt-10 w-full md:w-1/3 mx-auto">
            {servicesData.map((currEle, index) => {
              return (
                <Fade>
                  <div className="flex flex-row gap-5">
                    <img src={dotImage} alt="dot" />
                    <p
                      key={`service-${index}`}
                      className="flex items-start justify-between py-2 text-lg"
                    >
                      {currEle}
                    </p>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
}
