// import aboutMe from "../resources/svgs/AboutUs.svg";
// import aboutBanner from "../resources/svgs/bannerImage.svg";
import Container from "./Container";
import VidioticsAboutUs from "../resources/svgs/Vidiotics_LOGO-New.svg";
import { Slide, Fade } from "react-awesome-reveal";
export default function AboutUs() {
  return (
    <div className="mt-[10%]">
      {/* <div className="w-2/6 mx-auto my-10">
        <Fade direction="down">
          <img src={aboutMe} alt="aboutMeText" className="mx-auto" />
        </Fade>
      </div> */}
      <Container>
        <div className="flex flex-col gap-10 lg:flex-row lg:gap-10 mx-auto w-10/12 mt-20">
          <div className="basis-1/2 my-auto">
            <Fade direction="down">
              <div className="basis-1/2">
                <img
                  src={VidioticsAboutUs}
                  alt="aboutMeText"
                  className="block lg:hidden mx-auto"
                />
              </div>
            </Fade>
            <Slide direction="left">
              <p className="text-2xl lg:text-3xl font-semibold mt-20 lg:mt-0 text-center md:text-left">
                VIDIOTICS
              </p>
              <p className="text-sm lg:text-lg font-medium my-10">
                YOUR CREATIVE AGENCY SPECIALIZING IN VIDEO EDITING EXCELLENCE.
                AT VIDIOTICS, WE SPECIALIZE IN TURNING IDEAS INTO IMPACTFUL
                FILMS THAT LEAVE A LASTING IMPRESSION! IF YOU WANT TO GRAB YOUR
                AUDIENCE'S ATTENTION AND LEAVE A LASTING IMPRESSION, YOU'RE IN
                THE RIGHT PLACE.
              </p>
              <p className="text-xl lg:text-2xl font-semibold main-color mb-10">
                GENERATED OVER 4 MILLION+ VIEWS ACROSS SOCIALS FOR MY CLIENTS!
              </p>
              <p className="text-[10px] md:text-sm font-normal leading-4 md:leading-auto">
                AT VIDIOTICS, OUR TEAM OF VIDEO EDITING EXPERTS IS DEDICATED TO
                BRINGING YOUR VISION TO LIFE. WITH A PASSION FOR CINEMATIC
                STORYTELLING, SOUND DESIGN, AND VISUAL CREATIVITY, WE CREATE
                IMPACTFUL VIDEOS THAT RESONATE WITH YOUR AUDIENCE. OUR
                COMMITMENT TO CLEAR COMMUNICATION ENSURES YOUR CONTENT IS
                MEMORABLE. WE BELIEVE IN THE POWER OF VIDEO TO CONNECT, INSPIRE,
                AND ENTERTAIN. LET'S COLLABORATE TO CRAFT COMPELLING STORIES AND
                DELIVER UNFORGETTABLE EXPERIENCES TOGETHER
              </p>
            </Slide>
          </div>

          <div className="basis-1/2">
            <Slide direction="right">
              <img
                src={VidioticsAboutUs}
                alt="aboutMeText"
                className="hidden lg:block"
              />
            </Slide>
          </div>
        </div>
      </Container>
    </div>
  );
}
