import React from "react";
import MyWorks from "../resources/svgs/OurWorks.svg";
import Container from "./Container";
import ShortForm from "./Works/ShortForm";
import LongForm from "./Works/LongForm";
import { Fade } from "react-awesome-reveal";
// import ThumbNails from "./Works/ThumbNails";
export default function Works() {
  return (
    <div className="mt-20" id="portfolioId">
      <Fade direction="down">
        <img src={MyWorks} alt="MyWorks" className="w-2/6 md:w-[30%] mx-auto" />
      </Fade>
      <Container>
        <div className="mt-20 mx-auto">
          <ShortForm />
        </div>
        <div className="mt-20 mx-auto">
          <LongForm />
        </div>
        {/* <div className="mt-20 mx-auto">
          <ThumbNails />
        </div> */}
      </Container>
    </div>
  );
}
