/* eslint-disable jsx-a11y/iframe-has-title */
import Container from "./Container";
// import BannerVideo from "../resources/videos/Banner Aspect Ratio.mp4";
import { Fade } from "react-awesome-reveal";
export default function MainVideo() {
  return (
    <div className="mt-[5rem]">
      <Container>
        <Fade direction="left" delay={2}>
          <p className="text-5xl main-color text-center mb-10">
            BEHIND EVERY FRAME LIES A STORY.
          </p>
        </Fade>
        {/* <div className="w-full h-full lg:h-[90%] mx-auto"> */}
          {/* <video src={BannerVideo} autoPlay loop muted /> */}
          {/* <iframe
            src="https://drive.google.com/file/d/1iSqzrNvzPSFeJNqWMkaKJM-0el83wktF/preview"
            width="100%"
            height="100%"
            allow="autoplay"
          ></iframe> */}
        {/* </div> */}
      </Container>
    </div>
  );
}
